import * as React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import { animate } from "framer-motion"
import { Flex, Heading, Box, Text } from "theme-ui"
import { SectionHeader, SectionParagraph } from "components/various"
import { Orange } from "components/various"
import { StaticImage } from "gatsby-plugin-image"
import TextLoop from "components/TextLoop"

const Counter = ({ t, s, o }) => {
  const ref = React.useRef()
  const [isIntersecting, setIsIntersecting] = React.useState(false)
  const observer = React.useRef()
  React.useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) =>
      setIsIntersecting(entry.isIntersecting)
    )

    return () => {
      observer.current = null
    }
  }, [])
  React.useEffect(() => {
    if (observer.current && ref.current) observer.current.observe(ref.current)
    return () => {
      if (observer.current) observer.current.disconnect()
    }
  }, [ref.current, observer.current])
  React.useEffect(() => {
    const controls = animate(0, t, {
      duration: 1,
      onUpdate(value) {
        if (ref.current) ref.current.textContent = Math.floor(value)
      },
    })
    return () => controls.stop()
  }, [isIntersecting])

  return (
    <div
      sx={{
        fontWeight: "bold",
        fontSize: s,
        color: o ? "primary" : "initial",
      }}
      ref={ref}
    />
  )
}

const quotes = [
  "أقرب الطرق دائماً هي أبعدها عن الذهن المحدود",
  "الإنسان حيث وضع نفسه فان وضعها اتضعت وان رفعها ارتفعت",
  "لاترم سهما يعييك رده",
  "لاتشاور عالما في امر تافه ولا تافها في امر جلل",
  "ليست العبرة بالكم ولكن بالكيف",
  "الفرق بين الإنسان الناجح والآخرين هو ليس نقص القوة، ولا نقص المعرفة، انما نقص الارادة",
]

const TeamPage = () => (
  <Layout>
    <Seo title="Team" />
    <StaticImage
      src="../images/team.png"
      loading="eager"
      quality={95}
      formats={["auto", "webp", "avif"]}
      sx={{ width: "full", height: "full" }}
    />
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        my: 2,
        p: 3,
        px: [2, 3, 3, 6, 7],
        justifyContent: "space-around",
      }}
    >
      <SectionHeader>Notre Equipe</SectionHeader>
      <Box>
        <SectionParagraph>
          Notre effectif total compte près de dix-neuf experts et consultants,
          dont trois experts comptables, membres de l'ordre des Experts
          Comptables de Tunisie, des ingénieurs, des reviseurs, des comptables
          et des financiers et deux partenaires en IT et Stratégie. Tous nos
          collaborateurs sont diplômés de facultés ou de grandes écoles
          tunisiennes et étrangères.
        </SectionParagraph>
        <SectionParagraph>
          L'équipe permanente d'ACB comporte <Orange text="un (1)" /> associé
          gérant, <Orange text="un (1)" />
          &nbsp; associé directeur exécutif, <Orange text="un (1)" /> associé
          directeur technique, <Orange text="trois (3)" /> managers,&nbsp;
          <Orange text="Six (6)" /> chefs de mission et&nbsp;
          <Orange text="douze (12)" />
          &nbsp; auditeurs consultants séniors et juniors.
        </SectionParagraph>
        <SectionParagraph>
          Par ailleurs, nous faisons appel à des experts métiers pour des
          interventions ponctuelles, notamment dans le cadre des missions de
          diagnostic, d'évaluation d'entreprises, ou de restructuration de
          consultation juridique et fiscale et d'audit des systèmes
          d'information. En pratique, notre équipe de professionnels,
          disponibles, réactifs et expérimentés vous offre une approche
          pragmatique et des solutions adaptées aux problématiques et aux enjeux
          de votre environnement, ceci dans un réel souci d'excellence et de
          rigueur.
        </SectionParagraph>
        <SectionParagraph>
          ACB est dotée des outils de travail les plus appropriés pour assurer
          un bon déroulement des missions conformément aux normes et
          réglementations en vigueur (manuel d'audit, matériel et applications
          informatiques, etc.).
        </SectionParagraph>
        <SectionParagraph>
          La supervision des missions est assurée exclusivement par des experts
          comptables associés. Le personnel technique bénéficie d'actions de
          formation continue pour améliorer son efficacité. Avant la
          présentation des rapports de fin de mission, chaque dossier de travail
          fait l'objet d'un contrôle de qualité exercé par un expert comptable
          associé (non responsable de la mission).
        </SectionParagraph>
        <SectionParagraph>
          ACB fait appel à des consultants externes pour la réalisation des
          travaux spécifiques de conseil, d'organisation et d'audit des
          entreprises (audit de production, conception et mise en place de
          systèmes informatiques, etc.) qui font partie intégrante de sa mission
          et nécessitent des compétences techniques particulières.
        </SectionParagraph>
        <SectionParagraph>
          En outre, notre firme fait appel à des consultants externes dans
          différentes spécialités dont notamment:
        </SectionParagraph>
        <ul sx={{ pl: 4 }}>
          <li>La Géologie</li>
          <li>La Géophysique</li>
          <li>Les accords et les contrats pétroliers</li>
          <li>L'audit des coûts pétroliers</li>
          <li>L'informatique (partenariat avec Innotech Consulting)</li>
          <li>Les ingénieurs Télécom</li>
          <li>Les avocats</li>
          <li>Les Mines</li>
          <li>
            Les ingénieurs Electricité et Gaz (en faisant recours à la STEG
            internationale)
          </li>
          <li>La gestion des ressources humaines</li>
        </ul>
      </Box>
      <SectionHeader>Structure Permanente</SectionHeader>
      <Flex
        sx={{ my: 4, flexDirection: "column", width: "100%", maxWidth: 800 }}
      >
        <Flex sx={{ flexDirection: ["column", "row"] }}>
          <Flex
            sx={{
              my: 3,
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Heading as={"h3"} sx={{ textAlign: "center" }}>
              Associé: Directeur technique
            </Heading>
            <Heading as={"h4"} sx={{ color: "primary" }}>
              Hilal OUALI
            </Heading>
            <Text sx={{ textAlign: "center" }}>Expert-comptable diplômé</Text>
            <Flex>
              <Counter t={23} />
              &nbsp;ans d'expérience
            </Flex>
          </Flex>
          <Flex
            sx={{
              my: 3,
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Heading as={"h3"} sx={{ textAlign: "center" }}>
              Associé: Directeur exécutif du cabinet
            </Heading>
            <Heading as={"h4"} sx={{ color: "primary" }}>
              Mohamed MIGAOU
            </Heading>
            <Text sx={{ textAlign: "center" }}>Expert-comptable diplômé</Text>
            <Flex>
              <Counter t={23} />
              &nbsp;ans d'expérience
            </Flex>
          </Flex>
        </Flex>
        <Flex sx={{ justifyContent: "center", my: 3 }}>
          <Flex
            sx={{
              my: 3,
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Heading as={"h3"}>Associé: Gérant</Heading>
            <Heading as={"h4"} sx={{ color: "primary" }}>
              Oussema BAHLOUL
            </Heading>
            <Text sx={{ textAlign: "center" }}>
              Expert-comptable mémorialiste
            </Text>
            <Flex>
              <Counter t={11} />
              &nbsp;ans d'expérience
            </Flex>
          </Flex>
        </Flex>
        <Flex sx={{ justifyContent: "center", my: 3 }}>
          <Flex sx={{ flexDirection: "column", alignItems: "center" }}>
            <Heading as={"h3"}>Partenaire: Expert IT et ERP</Heading>
            <Heading as={"h4"} sx={{ color: "primary" }}>
              Imed MIGAOU
            </Heading>
            <Text>Ingénieur IT</Text>
            <Flex>
              <Counter t={25} />
              &nbsp;ans d'expérience
            </Flex>
          </Flex>
        </Flex>
        <Flex>
          <Flex sx={{ flex: 1, flexDirection: "column", alignItems: "center" }}>
            <Counter t={3} s={6} o={true} />
            <Heading as={"h3"}>Managers</Heading>
            <Heading as={"h4"}>Réviseurs</Heading>
            <Text>12 ans d'expérience</Text>
          </Flex>
          <Flex sx={{ flex: 1, flexDirection: "column", alignItems: "center" }}>
            <Counter t={6} s={6} o={true} />
            <Heading as={"h3"}>Chef de mission</Heading>
            <Heading as={"h4"} sx={{ textAlign: "center" }}>
              Mastère comptabilité et finances
            </Heading>
            <Text>6 ans d'expérience</Text>
          </Flex>
        </Flex>
        <Flex>
          <Flex sx={{ flex: 1, flexDirection: "column", alignItems: "center" }}>
            <Counter t={6} s={5} o={true} />
            <Heading as={"h3"}>Auditeur consultant (senior)</Heading>
            <Heading as={"h4"}>Maitrise comptabilité</Heading>
            <Text>5 ans d'expérience</Text>
          </Flex>
        </Flex>
        <Flex>
          <Flex sx={{ flex: 1, flexDirection: "column", alignItems: "center" }}>
            <Counter t={6} s={4} o={true} />
            <Heading as={"h3"}>Auditeur consultant (junior)</Heading>
            <Heading as={"h4"}>TEG & ISET comptabilité</Heading>
            <Text>3 ans d'expérience</Text>
          </Flex>
        </Flex>
      </Flex>
      <SectionHeader>Organisation interne</SectionHeader>
      <Box>
        <SectionParagraph>
          Notre cabinet comporte, outre le département administratif et qualité,
          quatre départements techniques à savoir : un département organisation
          et formation, un département audit, un département assistance et de
          sous-traitance (Outsourcing) et un département juridique et fiscal.
        </SectionParagraph>
        <SectionParagraph>
          Chaque département dispose d'une structure anglo-saxonne. Il est de ce
          fait confié à un ingénieur directeur (expert comptable diplômé)
          assisté par des ingénieurs managers et chefs de mission en matière
          d'organisation et d'audit. Ces derniers sont eux-mêmes assistés par
          des collaborateurs titulaires de la CES révision comptable, maîtrise
          en gestion comptable ou diplômes de techniciens en comptabilité.
        </SectionParagraph>
        <SectionParagraph>
          Soucieux d'assurer à chaque mission son efficacité maximale, nous
          avons établi des liens de coopération très étroits entre les quatre
          départements techniques. Ainsi, et à titre indicatif, le département
          organisation est dans la mesure du possible se trouve impliqué dans la
          phase diagnostique (évaluation des procédures de contrôle interne) des
          missions d'audit.
        </SectionParagraph>
        <SectionParagraph>
          De son côté, le département organisation a recours aux trois autres
          départements pour les points techniques qui relèvent de leur
          compétence. L'expérience montre en effet que rien n'est aussi
          constructif que la consolidation des connaissances et des expériences
          acquises.
        </SectionParagraph>
        <SectionParagraph>
          Le département informatique est dirigé par un ingénieur en
          informatique partenaire de notre structure et disposant d'une société
          de consulting “InnotechConsulting” dont la structure comporte des
          spécialistes IT en matière d'ERP plateforme ORACLE (partenaire
          officiel ORACLE). Ce département intervient pour les sociétés
          disposant de systèmes intégrés pour le traitement des données
          comptables et de gestion.
        </SectionParagraph>
        <SectionParagraph>
          Dans ce cadre, son intervention s'inscrit principalement dans
          l'évaluation du système en vigueur en vue de suggérer les actions
          correctrices. Le département informatique est chargé également de la
          conception des systèmes de traitement de données dans les missions
          d'organisation des systèmes informatisés.
        </SectionParagraph>
        <SectionParagraph>
          Notre société est également en partenariat avec un cabinet d'avocat de
          renommé en matière de droit des affaires.
        </SectionParagraph>
      </Box>
    </Flex>
    <TextLoop texts={quotes} scrollDirection="right" />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Team" />

export default TeamPage
